import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/file_storage/download_file/?uid=';

async function downloadLocalStorage(pageUid, filePath, handler, key) {
  let ans = null;
  try {
    ans = await fetch(ROOT_URL + REQUEST_LINK + pageUid + '&file_path=' + filePath, {
      method: 'GET',
      credentials: 'include',
    });
    ans = await ans.text();
    return ans;
  } catch {
    return 'error';
  }
  // fetch(ROOT_URL + REQUEST_LINK + pageUid + '&file_path=' + filePath, {
  //   method: 'GET',
  //   credentials: 'include',
  // }).then((response) => response.text()).then((html) => localStorage.setItem(key, html));
  // handler('ok');
}

export default downloadLocalStorage;
