<template>
  <div>
    <ButtonComponent v-if="this.canEditStruct" class="edit-struct-button"
                    label="Добавить / Удалить"
                    elementType="hover"
                    elementStatus="selected"
                    iconType="plus"
                    iconPosition="left"
                    @click_to_button="this.openStructEditor"/>

    <ButtonComponent v-if="this.isDeleteMode"
                    label="Выйти из режима удаления"
                    elementType="default-bg"
                    @click_to_button="() => this.isDeleteMode = false"/>

    <uniTable
        :draw_info="this.drawComponentInfo"
        :draw_data="this.drawData"
        @next_select="this.openNextLevel"
    />
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';

import uniTable from '@/reportsLayout/views/uni_table.vue';
import handleFileAction from './handleFileAction.vue';

import deleteObject from '@/assets/dataSources/fileStorage/deleteObject.js';
import FileStructEditor from './FileStructEditor.vue';

export default {
  components: {
    // LabelComponent,
    ButtonComponent,
    uniTable,
  },
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  data: () => ({
    isDeleteMode: false,
  }),
  emits: ['result'],
  watch: {
  },
  methods: {
    async openHandleFileAction(filePath) {
      await window.openWindow({
        caption: 'Скачать файл | Открыть файл',
        component: handleFileAction,
        componentProps: {
          filePath: filePath, // this.drawData[selectIdx].columns.File.path,
          sendFiles: this.params.sendFiles,
          filesDownloader: this.params.filesDownloader,
          filesLocalDownloader: this.params.filesLocalDownloader,
        },
      });
    },
    async openStructEditor() {
      const editorResult = await window.openWindow({
        caption: 'Выберите действие',
        // type: 'bottom',
        component: FileStructEditor,
        componentProps: {
          curPath: this.curPath, // this.drawData[selectIdx].columns.File.path,
          sendFiles: this.params.sendFiles,
          filesLocalDownloader: this.params.filesLocalDownloader,
        },
      });

      this.isDeleteMode = editorResult == 'delete_mode';
      if (editorResult == 'upd_struct') {
        this.$emit('result', 'upd_struct');
      }
    },
    async openNextLevel(nextView, selectIdx) {
      if (this.isDeleteMode) {
        const selectResult = await window.openYNWindow(
            'Вы уверены что хотите удалить этот и все дочерние объекты?\nЭто действие нельзя будет отменить!',
            'Удаление объекта',
        );

        if (selectResult != 'yes') return;

        const pageUid = this.$store.state.current_page_info.uid;
        const objPath = this.drawData[selectIdx].columns.File.path;
        deleteObject(pageUid, objPath, () => this.$emit('result', 'upd_struct'));
        return;
      }

      if (nextView.next_type_data.type == 'download_file') {
        if (this.canEditStruct) {
          const filePath = nextView.next_level[0].path;
          this.openHandleFileAction(filePath);
        } else {
          this.filesDownloader(nextView.next_level[0].path);
        }
        return;
      }

      const winRes = await window.openWindow({
        caption: nextView.modalCaption,
        type: 'bottom',
        component: this.selfComponent,
        componentProps: {
          drawStruct: nextView,
          filesDownloader: this.filesDownloader,
          sendFiles: this.sendFiles,
          filesLocalDownloader: this.filesLocalDownloader,
          curPath: this.drawData[selectIdx].columns.File.path,
          canEditStruct: this.canEditStruct,
          selfComponent: this.selfComponent,
        },
      });

      if (winRes == 'upd_struct') {
        this.$emit('result', 'upd_struct');
      }
    },
  },
  computed: {
    selfComponent() {
      return this.params.selfComponent;
    },
    canEditStruct() {
      return this.params.canEditStruct;
    },
    filesDownloader() {
      return this.params.filesDownloader;
    },
    filesLocalDownloader() {
      return this.params.filesLocalDownloader;
    },
    sendFiles() {
      return this.params.sendFiles;
    },
    drawStruct() {
      return this.params.drawStruct;
    },
    curPath() {
      return this.params.curPath;
    },
    drawComponentInfo() {
      return this.drawStruct.next_type_data;
    },
    drawData() {
      return this.drawStruct.next_level;
    },
  },
};
</script>

<style lang="less">

.edit-struct-button {
  margin: auto;
}

</style>
