import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/file_storage/download_preview_file/?uid=';

async function downloadPreviewLocalStorage(storageUid, sourceUid, filePath, handler, key) {
  let ans = null;
  try {
    ans = await fetch(ROOT_URL + REQUEST_LINK + storageUid + '&source=' + sourceUid + '&file_path=' + filePath, {
      method: 'GET',
      credentials: 'include',
    });
  } catch {
    return 'error';
  }
  ans = await ans.text();
  return ans;
  // }).then((response) => response.text()).then((html) => handler(html));
}

export default downloadPreviewLocalStorage;
