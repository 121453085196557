<template>
  <iframe ref="previewFrame"></iframe>
</template>

<script>

export default {
  mounted() {
    this.loadIframeContent();
    window.addEventListener('message', this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    async loadIframeContent() {
      if (this.htmlDrawFile) {
        const iframe = this.$refs.previewFrame;
        const doc = iframe.contentDocument || iframe.contentWindow.document;
        doc.open();
        doc.write(this.htmlDrawFile);
        doc.close();
      }
    },
    async handleMessage(event) {
      if (event.data.fileName) {
        const fileBlob = new Blob([event.data.fileData], {type: event.data.fileType});
        const file = new File([fileBlob], event.data.fileName, {type: event.data.fileType});
        this.send_files('', file, () => this.$emit('result', 'upd_struct'));
      }
      if (event.data.download) {
        const test = await this.filesLocalDownloader(event.data.download);
        event.source.postMessage({'Download': test}, event.origin);
      }
    },
  },
  computed: {
    send_files() {
      return this.params.send_files;
    },
    htmlDrawFile() {
      return this.params.draw_file;
    },
    filesLocalDownloader() {
      return this.params.files_local_downloader;
    },
  },
};
</script>

<style>
iframe {
  background-color: white;
  width: 75vw;
  height: 70vh;
}
</style>
