<template>
  <div>
    <Teleport to="#page-tools">
      <div>
        <ButtonComponent iconUrl="/icons/system/file-plus.svg" elementType="default" iconSize="27" @click="this.openPageForm" />
      </div>
    </Teleport>

    <SpinnerComponent v-if="this.isPreloading" style="width: 50px;" :width="5" />

    <LabelComponent v-if="this.dataRequestError != null" :label_text="this.dataRequestError" style="color: red;"/>

    <FileStoragePainter
          :raw_storage_data="this.curStructData"
          :files_downloader="this.filesDownloader"
          :files_local_downloader="this.filesLocalDownloader"
    />
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';

import getPreviewStorageStruct from '@/assets/dataSources/fileStorage/getPreviewStorageStruct.js';
import getDataSourceInfo from '@/assets/dataSources/subscription/getDataSourceInfo.js';

import FileStoragePainter from './FileStoragePainter.vue';
import PageForm from '@/windows/PageForm.vue';

import downloadPreviewStorageFile from '@/assets/dataSources/fileStorage/downloadPreviewStorageFile.js';
import downloadPreviewLocalStorage from '@/assets/dataSources/fileStorage/downloadPreviewLocalStorage';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    // ReportPainter,
    SpinnerComponent,
    FileStoragePainter,
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      this.updateReportPath();
      this.reloadReport();
    },
    curStructData() {
      if (this.curStructData == null) {
        this.pageNameChanger('Предпросмотр хранилища');
        return;
      }

      this.pageNameChanger(
          'Источник - ' + this.curSourceRawInfo.view +
          ': Файловое хранилище',
      );
    },
    curSourceRawInfo() {
      if (this.curSourceRawInfo == null) return;
      this.pageNameChanger('Источник - ' + this.curSourceRawInfo.view + ': Предпросмотр отчета');
    },
  },
  data: ()=> ({
    // formRequestError: null,
    dataRequestError: null,
    requestVersion: 0,
    isPreloading: false,
    // isReportDataRequesting: false,
    curSourceRawInfo: null,
    curSourceUid: null,
    curStorageUid: null,
    curStructData: null,
    // curReportForm: null,
    // curReportData: null,
  }),
  props: {
    pageNameChanger: null,
  },
  mounted() {
    // this.pageNameChanger('kek');
    this.updateReportPath();
    this.reloadReport();
  },
  computed: {
  },
  methods: {
    filesDownloader(filePath) {
      downloadPreviewStorageFile(this.curStorageUid, this.curSourceUid, filePath);
    },
    async filesLocalDownloader(filePath) {
      let htmlFile = null;
      htmlFile = await downloadPreviewLocalStorage(this.curStorageUid, this.curSourceUid, filePath, () => '', 'html');
      return htmlFile;
    },
    openPageForm() {
      window.openWindow({
        caption: 'Добавление страницы отчета',
        component: PageForm,
        componentProps: {
          page_type: 'storage',
          initial_name: 'Файловое хранилище',
          data_source_uid: this.curSourceUid,
          initial_object_uid: this.curStorageUid,
        },
      });
    },
    updateReportPath() {
      this.curSourceUid = this.$route.params.sourceUid;
      this.curStorageUid = this.$route.params.storageUid;
    },
    reloadReport() {
      // this.formRequestError = null;
      this.dataRequestError = null;
      // this.isReportDataRequesting = false;
      // this.curReportForm = null;
      // this.curReportData = null;
      this.curStructData = null;

      this.curSourceRawInfo = null;

      this.requestVersion += 1;
      this.isPreloading = true;

      const requestReportFormVersion = this.requestVersion;

      getDataSourceInfo((result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        if (result._error == true) {
          if (result.status == null) this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.dataRequestError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.curSourceRawInfo = result;

        getPreviewStorageStruct(this.curStorageUid, this.curSourceRawInfo, (result) => {
          if (requestReportFormVersion != this.requestVersion) return;

          this.isPreloading = false;

          if (result._error == true) {
            if (result.status == null) this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
            else this.dataRequestError = `${result.text} Статус: ${result.status}`;
            return;
          }

          this.curStructData = result.packed_data;
        });
      }, this.curSourceUid);
    },
  },
};
</script>
