<template>
  <div>
    <SpinnerComponent v-if="this.isPreloading" style="width: 50px;" :width="5" />

    <LabelComponent v-if="this.dataRequestError != null" :label_text="this.dataRequestError" style="color: red;"/>

    <FileStoragePainter
          :raw_storage_data="this.curStructData"
          :files_downloader="this.filesDownloader"
          :send_Files="this.sendFiles"
          :files_local_downloader="this.filesLocalDownloader"
          :can_edit_struct="this.canEditStruct"
          @requireReload="this.reloadReport"
    />
  </div>
</template>

<script>
// import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';

import getStorageStruct from '@/assets/dataSources/fileStorage/getStorageStruct.js';

import FileStoragePainter from './FileStoragePainter.vue';

import downloadStorageFile from '@/assets/dataSources/fileStorage/downloadStorageFile.js';
import downloadLocalStorage from '@/assets/dataSources/fileStorage/downloadLocalStorage';
import uploadFile from '@/assets/dataSources/fileStorage/uploadFile.js';

export default {
  components: {
    // ButtonComponent,
    LabelComponent,
    SpinnerComponent,
    FileStoragePainter,
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      this.updateReportPath();
      this.reloadReport();
    },
    // curStructData() {
    //   if (this.curStructData == null) {
    //     this.pageNameChanger('Предпросмотр хранилища');
    //     return;
    //   }

    //   this.pageNameChanger(
    //       'Источник - ' + this.curSourceRawInfo.view +
    //       ': Файловое хранилище',
    //   );
    // },
    // curSourceRawInfo() {
    //   if (this.curSourceRawInfo == null) return;
    //   this.pageNameChanger('Источник - ' + this.curSourceRawInfo.view + ': Предпросмотр отчета');
    // },
  },
  data: ()=> ({
    dataRequestError: null,
    requestVersion: 0,
    isPreloading: false,
    // curSourceRawInfo: null,
    // curSourceUid: null,
    // curStorageUid: null,
    curPageUid: null,
    curStructData: null,
  }),
  props: {
    pageNameChanger: null,
  },
  mounted() {
    this.updateReportPath();
    this.reloadReport();
  },
  computed: {
    canEditStruct() {
      return this.$store.state.current_page_info ? this.$store.state.current_page_info.can_edit_storage !== false : false;
    },
    curPath() {
      return this.params.curPath;
    },
  },
  methods: {
    sendFiles(filePath, file, handler) {
      uploadFile(this.curPageUid, filePath, file, handler);
    },
    filesDownloader(filePath) {
      downloadStorageFile(this.curPageUid, filePath, () => '');
    },
    async filesLocalDownloader(filePath) {
      return await downloadLocalStorage(this.curPageUid, filePath, () => '', 'html');
    },
    updateReportPath() {
      // this.curSourceUid = this.$route.params.sourceUid;
      // this.curStorageUid = this.$route.params.storageUid;
      this.curPageUid = this.$store.state.current_page_info.uid;
    },
    reloadReport() {
      this.dataRequestError = null;
      this.curStructData = null;

      // this.curSourceRawInfo = null;

      this.requestVersion += 1;
      this.isPreloading = true;

      const requestReportFormVersion = this.requestVersion;

      getStorageStruct(this.curPageUid, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isPreloading = false;

        if (result._error == true) {
          if (result.status == null) this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.dataRequestError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.curStructData = result.packed_data;
      });

      // getDataSourceInfo((result) => {
      //   if (requestReportFormVersion != this.requestVersion) return;

      //   if (result._error == true) {
      //     if (result.status == null) this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
      //     else this.dataRequestError = `${result.text} Статус: ${result.status}`;
      //     return;
      //   }

      //   this.curSourceRawInfo = result;


      // }, this.curSourceUid);
    },
  },
};
</script>
