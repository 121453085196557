<template>
    <div class="flex-row">
        <ButtonComponent  label=" Скачать файл "
                          elementType="default-bg"
                          labelType="button/small/medium 14"
                          @click_to_button="this.downloadFiles"/>
        <ButtonComponent  label="Предпросмотр"
                          :elementStatusModifier="!this.isHtmlFile ? 'disabled' : undefined"
                          elementType="default-bg"
                          labelType="button/small/medium 14"
                          @click_to_button="this.openFilePreview"/>
    </div>
</template>

<script type="text/javascript">
import ButtonComponent from '@/units/forms/RichButton.vue';
import HtmlFilePreview from './HtmlFilePreview.vue';


export default {
  components: {
    ButtonComponent,
  },
  data: () => ({
    sendFile: null,
    HtmlFile: null,
    isHtmlFile: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  mounted() {
    this.checkIfHtmlFile();
  },
  methods: {
    async checkIfHtmlFile() {
      if (this.filePath.endsWith('.html')) {
        this.HtmlFile = await this.filesLocalDownloader(this.filePath);
        this.isHtmlFile = true;
      }
    },
    async openFilePreview() {
      window.openWindow({
        caption: 'Предпросмотр',
        component: HtmlFilePreview,
        componentProps: {
          draw_file: this.HtmlFile,
          send_files: this.params.sendFiles,
          files_local_downloader: this.params.filesLocalDownloader,
        },
      });
    },
    async downloadFiles() {
      this.filesDownloader(this.filePath);
    },
  },
  computed: {
    filePath() {
      return this.params.filePath;
    },
    filesDownloader() {
      return this.params.filesDownloader;
    },
    filesLocalDownloader() {
      return this.params.filesLocalDownloader;
    },
  },
};
</script>
