import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/file_storage/download_preview_file/?uid=';

// import downloadBLOB from '@/assets/downloadBLOB';

function downloadPreviewStorageFile(storageUid, sourceUid, filePath) {
  window.open(
      ROOT_URL + REQUEST_LINK + storageUid + '&source=' + sourceUid + '&file_path=' + filePath, '_blank',
  ).focus();

  // store.commit('set_full_screen_loading', true);
  // fetch(ROOT_URL + REQUEST_LINK + storageUid + '&source=' + sourceUid + '&file_path=' + filePath, {
  //   method: 'GET',
  //   credentials: 'include',
  // }).then(async function(response) {
  //   // const objectTypeData = await response.json();
  //   // store.commit('set_full_screen_loading', false);
  //   if (response.status == 200) {
  //     // const contentType= response.headers.get('content-type');

  //     // console.log('response.headers', response.headers);

  //     let filename = response.headers.get('Content-Disposition');

  //     // console.log('filename', filename);

  //     filename = filename.match(/(?<=")(?:\\.|[^"\\])*(?=")/)[0];

  //     filename = decodeURI(filename);

  //     // console.log('filename', filename);

  //     const blobFile = await response.blob();
  //     // const blob = new Blob([curText], {type: contentType});
  //     const url = window.URL.createObjectURL(blobFile);
  //     // return {'result': {url}, 'is_file': true};

  //     downloadBLOB(url, filename);

  //     // console.log('response', response);

  //     // window.location.assign(url);

  //     handler('ok');
  //   }
  // }).catch((errData) => {
  //   // handler(false);
  // });
}

export default downloadPreviewStorageFile;
